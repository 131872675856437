import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { CreateFaceBookPageModel } from '../models/facebook-page-info'
import { InstagramUserInfo } from '../models/instagram-user-info'
import { CreateFaceBookGroupsModel } from '../models/facebook-group-info';
import { TwitterModel } from '../models/twitter-model';
import { PinterestModel } from '../models/Pinterest-model';
import { Observable } from 'rxjs';
import { switchMap, map, tap } from "rxjs/operators";



@Injectable({
  providedIn: 'root'
})
export class SocialMediaAccountsService {

  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) { }

  async addFacebookPagesData(userData: CreateFaceBookPageModel) {
    let token = 'Bearer ' + await localStorage.getItem('token')
    //let token = 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1laWRlbnRpZmllciI6InNhdXJhYmguc0BhYmhpbmF2aXRzb2x1dGlvbnMuaW4iLCJleHAiOjE3MTg1MTcyNjYsImlzcyI6IktpcmFuIiwiYXVkIjoiSnd0Q1JVRCJ9.krPrU-eRnLtQ47rvUII-EQp-SLSFFXEvNfFG9ezJT7g'
    let headers = new HttpHeaders({
      'Authorization': token
    });
    let options = {
      headers: headers
    };
    return this.http.post(this.baseUrl + '/Facebook/AddFacebookPagesData', userData, options)
  }

  async addInstagramUserInfo(instaUser: InstagramUserInfo) {
    let token = 'Bearer ' + await localStorage.getItem('token')
    let headers = new HttpHeaders({
      'Authorization': token
    });
    let options = {
      headers: headers
    };
    return this.http.post(this.baseUrl + '/Instagram/InsertInstagramAccounts', instaUser, options)
  }

  async logoutFacebookWithBrand(userId: any, brandId: any) {
    let token = 'Bearer ' + await localStorage.getItem('token')
    let headers = new HttpHeaders({
      'Authorization': token
    });
    let options = {
      headers: headers
    };
    return this.http.put(`${this.baseUrl}/Facebook/FacebookLogOut?UserUid=${userId}&BrandId=${brandId}`, {}, options)
  }

  async logoutInstagram(userId: any, brandId: any) {
    let token = 'Bearer ' + await localStorage.getItem('token')
    let headers = new HttpHeaders({
      'Authorization': token
    });
    let options = {
      headers: headers
    };
    return this.http.put(`${this.baseUrl}/Instagram/LogoutFromInstagram?UserUid=${userId}&BrandId=${brandId}`, {}, options)
  }
  async downloadInstaAcc(keyword: any, userId: any, brandId: any, SocialMediaUserId: any) {
    let token = 'Bearer ' + await localStorage.getItem('token');
    console.log('token', token)
    let headers = new HttpHeaders({
      'Authorization': token
    })
    const options = {
      headers: headers,
    }
    return this.http.get(`${this.baseUrl}/Instagram/GetInstagramAccountsByUserIdBrandIdKeyword?keyword=${keyword}&userUid=${userId}&brandId=${brandId}&SocialMediaUserId=${SocialMediaUserId}`, options)
  }

  async insertInstaAcc(InsertInsta: InstagramUserInfo) {
    let token = 'Bearer ' + await localStorage.getItem('token');
    console.log('token', token)
    let headers = new HttpHeaders({
      'Authorization': token
    })
    const options = {
      headers: headers,
    }
    return this.http.post(`${this.baseUrl}/Instagram/InsertInstagramAccounts`, InsertInsta, options)
  }
  async logoutInstaAcc(UserUid: any, Instaigid: any, SocialMediaUserId: any) {
    let token = 'Bearer ' + await localStorage.getItem('token')
    console.log('token', token)
    let headers = new HttpHeaders({
      'Authorization': token
    })
    const options = {
      headers: headers,
    }
    return this.http.put(`${this.baseUrl}/Instagram/InstagramSinglePageLogOut?UserUid=${UserUid}&Instaigid=${Instaigid}&SocialMediaUserId=${SocialMediaUserId}`, {}, options)

  }

  async getFacebookGroupsByuser(socialMediaUserId: any, userId: any, brandId: any) {
    let token = 'Bearer ' + await localStorage.getItem('token');
    console.log('token', token)
    let headers = new HttpHeaders({
      'Authorization': token
    })
    const options = {
      headers: headers,
    }
    return this.http.get(`${this.baseUrl}/Facebook/GetFacebookGroupsBySocialMediaUSerIdBrandId?socialMediaUserId=${socialMediaUserId}&userId=${userId}&brandId=${brandId}`, options)
  }

  async addFacebookGroups(fbGroups: CreateFaceBookGroupsModel) {
    let token = 'Bearer ' + await localStorage.getItem('token');
    console.log('token', token)
    let headers = new HttpHeaders({
      'Authorization': token
    })
    const options = {
      headers: headers,
    }
    return this.http.post(`${this.baseUrl}/Facebook/AddFacebookGroups`, fbGroups, options)

  }

  async getFBpage(keyword: any, userId: any, brandId: any, SocialMediaUserId: any) {

    let token = 'Bearer ' + await localStorage.getItem('token');
    console.log('token', token)
    let headers = new HttpHeaders({
      'Authorization': token
    })
    const options = {
      headers: headers,
    }
    return this.http.get(`${this.baseUrl}/Facebook/GetFacebookPagesByKeywordBrandIdUserId?keyword=` + keyword + '&userUid=' + userId + '&brandId=' + brandId + '&SocialMediaUserId=' + SocialMediaUserId, options);
  }
  async FacebookSinglepageLogOut(SocialMediaUserId: any, UserUid: any, Pageid: any) {
    let token = 'Bearer ' + await localStorage.getItem('token');
    console.log('token', token)
    let headers = new HttpHeaders({
      'Authorization': token
    })
    const options = {
      headers: headers,
    }

    return this.http.put(`${this.baseUrl}/Facebook/FacebookSinglepageLogOut?SocialMediaUserId=` + SocialMediaUserId + '&UserUid=' + UserUid + '&Pageid=' + Pageid, {}, options)
  }


  getinstaaccount(keyword: any, userUid: any, brandId: any, SocialMediaUserId: any) {
    let token = 'Bearer ' + localStorage.getItem('token')
    console.log('token', token)
    let headers = new HttpHeaders({
      'Authorization': token
    })
    const options = {
      headers: headers,
    }
    return this.http.get(`${this.baseUrl}/Instagram/GetInstagramAccountsByUserIdBrandIdKeyword?keyword=${keyword}&userUid=${userUid}&brandId=${brandId}&SocialMediaUserId=${SocialMediaUserId}`, options)
  }
  async logOutInstaAccount(UserUid: any, Instaigid: any, SocialMediaUserId: any) {
    let token = 'Bearer ' + await localStorage.getItem('token')
    console.log('token', token)
    let headers = new HttpHeaders({
      'Authorization': token
    })
    const options = {
      headers: headers,
    }
    return this.http.put(`${this.baseUrl}/Instagram/InstagramSinglePageLogOut?UserUid=${UserUid}&Instaigid=${Instaigid}&SocialMediaUserId=${SocialMediaUserId}`, {}, options)

  }


  async logoutFacebookGroups(UserUid: any, groupId: any, SocialMediaUserId: any) {
    let token = 'Bearer ' + await localStorage.getItem('token')
    let headers = new HttpHeaders({
      'Authorization': token
    });
    let options = {
      headers: headers
    };
    return this.http.put(`${this.baseUrl}/Facebook/Disconnectfbgroup?UserUid=${UserUid}&groupId=${groupId}&SocialMediaUserId=${SocialMediaUserId}`, {}, options)
  }

  twitterRequestToken() {
    return this.http.get(`${this.baseUrl}/Twitter/TwitterRequestToken`)
  }

  twitterRequestAccessToken(twitterModel: TwitterModel) {
    return this.http.post(`${this.baseUrl}/Twitter/TwitterAcessToken`, twitterModel)
  }

  async addTwitterData(twitterModel: TwitterModel) {
    let token = 'Bearer ' + await localStorage.getItem('token');
    console.log('token', token)
    let headers = new HttpHeaders({
      'Authorization': token
    })
    const options = {
      headers: headers,
    }
    return this.http.post(`${this.baseUrl}/Twitter/PostTwitterData`, twitterModel, options)

  }

  async logOutTwitterAccount(twitterUserId: any) {
    let token = 'Bearer ' + await localStorage.getItem('token')
    const UserUid = await localStorage.getItem('userId')
    console.log('token', token)
    let headers = new HttpHeaders({
      'Authorization': token
    })
    const options = {
      headers: headers,
    }
    return this.http.put(`${this.baseUrl}/Twitter/DisconnectTwitterAcoount?userUid=${UserUid}&twitterUserId=${twitterUserId}`, {}, options)

  }

  async logOutPinterstAccount(pinterstUserId: any) {
    let token = 'Bearer ' + await localStorage.getItem('token')
    const UserUid = await localStorage.getItem('userId')
    console.log('token', token)
    let headers = new HttpHeaders({
      'Authorization': token
    })
    const options = {
      headers: headers,
    }
    return this.http.put(`${this.baseUrl}/Pinterest/DisconnectPintrestAccount?userUid=${UserUid}&pinterestUserId=${pinterstUserId}`, {}, options)
  }

  async addPinterstData(PinterestModel: PinterestModel) {
    let token = 'Bearer ' + await localStorage.getItem('token');
    console.log('token', token)
    let headers = new HttpHeaders({
      'Authorization': token
    })
    const options = {
      headers: headers,
    }
    return this.http.post(`${this.baseUrl}/Pinterest/AddPinterestAccounts`, PinterestModel, options)
  }

  getPinterestAccounts(userId: any, brandId: any) {
    let token = 'Bearer ' + localStorage.getItem('token')
    console.log('token', token)
    let headers = new HttpHeaders({
      'Authorization': token
    })
    const options = {
      headers: headers,
    }
    return this.http.get(`${this.baseUrl}/Pinterest/GetPintrestAcoountByUserUidBrandId?userId=${userId}&brandId=${brandId}`, options)
  }

  getTwitterAccounts(userId: any, brandId: any) {
    let token = 'Bearer ' + localStorage.getItem('token')
    console.log('token', token)
    let headers = new HttpHeaders({
      'Authorization': token
    })
    const options = {
      headers: headers,
    }
    return this.http.get(`${this.baseUrl}/Twitter/GetTwitterAcoountByUserUid?userId=${userId}&brandId=${brandId}`, options)
  }
  // async logoutFacebookGroups(UserUid: any, groupId: any, SocialMediaUserId: any) {
  //   let token = 'Bearer ' + await localStorage.getItem('token')
  //   let headers = new HttpHeaders({
  //   'Authorization': token
  //   });
  //   let options = {
  //   headers: headers
  //   };
  //   return this.http.put(`${this.baseUrl}/Facebook/Disconnectfbgroup?UserUid=${UserUid}&groupId=${groupId}&SocialMediaUserId=${SocialMediaUserId}`, {}, options)
  //   }

  getFacebookFriends() {
    console.log("url-->");
    let apiUrl = 'https://graph.facebook.com/v18.0';



    const url = `${apiUrl}/me/friends`;
    console.log("url-->", url);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const params = {
      access_token: 'EAAKUEm4wJtkBOZC2WzpReGUC2BrZC0v582GBf6m6LioB93RWOSSEkxpSpbyGMGi3vZBueUmmEeC9K5DmsRzBAH36R7CMQhT5cUClAuc7oG45RDDA83X5C0pkhf9I8COabNegTwps9ood5DkiyO6bYVBRYW4eeRt9JwhyhiZCwFHUsWYD7fl4Dg5anYW9Q4l1Y9NZAwnja9ZALPgNjTdQZDZD'
    };

    return this.http.get(url, { headers, params });
  }
  async deleteFeed(feedId: any, pageId: any) {
    let token = 'Bearer ' + await localStorage.getItem('token');
    let headers = new HttpHeaders({
      'Authorization': token
    })
    let options = {
      headers: headers
    }
    return this.http.delete(`${this.baseUrl}/Facebook/DeleteFacebookPost?feedId=${feedId}&pageId=${pageId}`, options)
  }

  //download image on user system
  getData(url: string): Observable<string> {
    const proxyUrl = 'https://cors-anywhere.herokuapp.com/';

    const fullUrl = proxyUrl + url;

    url = "https://needly.in/img/_64845f04-4ebc-11e8-bd65-8f9614bffbbb.jpeg";
   // url="https://api.impartr.com/wwwroot/Social_Media/Instagram_Post/Insta_Stories/b7372751-e6bb-46f4-a2f5-c58fda41ee78-2024-12-05-default-image.jpg"
    console.log('fullUrl-->', fullUrl);

    return this.http
      .get(url, { responseType: "blob" })
      .pipe(switchMap(response => this.readFile(response)));

  }

  private readFile(blob: Blob): Observable<string> {
    return Observable.create(obs => {
      const reader = new FileReader();

      reader.onerror = err => obs.error(err);
      reader.onabort = err => obs.error(err);
      reader.onload = () => obs.next(reader.result);
      reader.onloadend = () => obs.complete();

      return reader.readAsDataURL(blob);
    });
  }

  download(img) {
    const imgUrl = img;
    console.log('img-->', img);

    const imgName = imgUrl.substr(imgUrl.lastIndexOf("/") + 1);
    console.log('imgName-->', imgName);

    this.http
      .get(img, { responseType: "blob" as "json" })
      .subscribe((res: any) => {
        const file = new Blob([res], { type: res.type });

        // IE
        // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        //   window.navigator.msSaveOrOpenBlob(file);
        //   return;
        // }

        const blob = window.URL.createObjectURL(file);
        const link = document.createElement("a");
        link.href = blob;
        link.download = imgName;

        // Version link.click() to work at firefox
        link.dispatchEvent(
          new MouseEvent("click", {
            bubbles: true,
            cancelable: true,
            view: window
          })
        );

        setTimeout(() => {
          // firefox
          window.URL.revokeObjectURL(blob);
          link.remove();
        }, 100);
      });
  }

  //Add pintrest Pin

  //https://api.impartr.com/api/Pinterest/AddPins

  async AddPinsImage(formData: FormData) {
    let token = 'Bearer ' + await localStorage.getItem('token');
    let headers = new HttpHeaders({
      'Authorization': token
    })
    const options = {
      headers: headers,
    }
    return this.http.post(`${this.baseUrl}/Pinterest/AddPins`, formData, options)
  }
  //get All Connected linkedIn pages
  async getLinkedInConnectedPage(userId, brandId) {
    let token = 'Bearer ' + await localStorage.getItem('token');
    let headers = new HttpHeaders({
      'Authorization': token
    })

    const options = {
      headers: headers,
    }

    return this.http.get(`${this.baseUrl}/LinkedIn/GetLinkdinAcoountByUserUidBrandId?userId=` + userId + '&brandId=' + brandId, options)
  }

 
}

