<div class="page-wrapper">
    <div class="container-fluid p-0">
      <div class="row">
        <div class="col-12">     
          <div class="login-card">
            <div>
              <div>
                <a class="logo" >
                  <img class="img-fluid for-light"height="40px"  width="180px" src="../../../assets/images/Impartr_Logo_light.png" alt="looginpage">
                  <img class="img-fluid for-dark"height="40px"  width="180px" src="../../../assets/images/Impartr_Logo_dark.png" alt="looginpage">
                </a>
              </div>
              <div class="login-main">
                <form class="theme-form" [formGroup]="otpForm">
                  <h4 style="text-align: center;"> {{'OTP Verifications' | translate}}</h4>
                 
                  <div class="form-group">
                    <label class="col-form-label mt-4" style="text-align: center;">
                      {{'Enter the OTP Code you Receive to' | translate}} {{userEmailId}} 
                    </label>
                     
                    <div class="row mt-4">
                      <div class="col" style="text-align: center;">
                        <ng-otp-input class="custom-otp-input" (onInputChange)="onOtpChange($event)"  
                        [config]="{length:5}"></ng-otp-input>
                        </div>
                    </div>
                  </div>
  
                  <div class="form-group">
                    <div class="row" *ngIf="resend==true">
                      <div class="col-12  m-t-10" style="text-align: center;">
                      <p>{{ time | async }} </p>
                    </div>
                    </div>
            
  
            
                    <form [formGroup]="aFormGroup" style="text-align: center;">
                      <ngx-recaptcha2 #captchaElem
                                      style="display: inline-block;"
                                      [siteKey]="siteKey"
                                      formControlName="recaptcha"
                                      (captchaResponse)="onCaptchaResolved($event)">
                      </ngx-recaptcha2>
                  </form>
                  
                  
                    <div class="row">
                      <div class="col-12">
                        <button class="btn btn-primary d-block w-100 m-t-10" (click)="otpVerify()" type="button">Verify</button>
                      </div>
                    </div>
  
                  </div>
                  <div class="text-center mt-4 mb-4"><span class="reset-password-link"> {{'If do not receive OTP?' | translate}}<a class="btn-link text-danger" (click)="resendOtp()" [routerLink]=''>{{'Resend' | translate}}</a></span></div>
                  <p class="mt-4 mb-0" style="text-align: center;">{{'Already have an password?' | translate}} <a class="ms-2" [routerLink]="''"> {{'Sign in' | translate}}</a></p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>