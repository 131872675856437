import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { Router } from "@angular/router";
import { FacebookLoginProvider, SocialAuthService, SocialUser } from "angularx-social-login";
import { jwtDecode } from "jwt-decode";
import { FaceBookUserInfoModel } from 'src/app/models/facebook-user-info';
import { CreateFaceBookPageModel } from 'src/app/models/facebook-page-info';
import { LoginService } from '../../services/login.service'
import { RegistrationModel } from "src/app/models/registration-model";
import Swal from 'sweetalert2';
import { AngularFireAuth } from '@angular/fire/compat/auth'
import { GoogleAuthProvider, GithubAuthProvider, FacebookAuthProvider } from '@angular/fire/auth';
import { CommonModule, DatePipe } from "@angular/common";
import { RegistrationService } from "src/app/services/registration.service";
import { NavService } from "src/app/shared/services/nav.service";
import { TranslateService } from "@ngx-translate/core";
import { DashboardService } from "src/app/services/dashboard.service";
import { endOfDay, startOfDay, subDays } from "date-fns";
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent } from "angular-calendar";
import { BrandService } from "src/app/services/brand.service";
import { SocialMediaAccountsService } from "src/app/services/social-media-accounts.service";
import { TwitterModel } from "src/app/models/twitter-model";
import { environment } from "src/environments/environment";

const redirectUrl = `${environment.redirectUrl}`;
declare var google: any;



const colors: any = {
  facebook: {
    primary: '#1877F2',
    secondary: '#1877F2'
  },
  insta: {
    primary: '#C13584',
    secondary: '#C13584'
  },
  tweeter: {
    primary: '#000000',
    secondary: '#000000'
  },
  pinterest: {
    primary: '#D30808',
    secondary: '#D30808'
  }
  ,
  fbGroup:{
    primary: ' #04A2D4',
    secondary: '#04A2D4'
  },
  linkedInd :{
   primary:'rgba(59, 88, 128, 1)',
   secondary: 'rgba(59, 88, 128, 1)'
  }
};
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {

  calendarData: any[] = [];
  public newUser = false;
  public loginForm: FormGroup;
  public show: boolean = false
  public errorMessage: any;
  fbUser: FaceBookUserInfoModel[] = new Array<FaceBookUserInfoModel>();
  fbPage: CreateFaceBookPageModel = new CreateFaceBookPageModel();
  socialUser: SocialUser;
  email: any
  registrationModel: RegistrationModel = new RegistrationModel();
  userData: RegistrationModel = new RegistrationModel();

  public config = {
    settings: {
      layout: 'Dubai',
      layout_type: 'ltr',
      layout_version: 'light-only',
      sidebar_type: 'default-sidebar',
      icon: 'stroke-svg'
    },
    color: {
      primary_color: '#3a5880',
      secondary_color: '#7ea3d0'
    }
  }
  post: any;
  activeDayIsOpen: boolean;
  filteredPosts: any;
  apiResponseDate: Date;
  title: any;
  socialMediaKeyword:any;
  events: CalendarEvent[] = [];
  eventList:any[];
  actions: CalendarEventAction[] = [
    {
      label: '<i class="fa fa-fw fa-pencil"></i>',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      }
    },
    {
      label: '<i class="fa fa-fw fa-times"></i>',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter(iEvent => iEvent !== event);
        this.handleEvent('Deleted', event);
      }
    }
  ];
  img: string;
  formattedDate: string;
  socialMediaColor: any;

  eventTimesChanged({
    event,
    newStart,
    newEnd
  }: CalendarEventTimesChangedEvent): void {
    event.start = newStart;
    event.end = newEnd;
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    // this.modalData = { event, action };
    // this.modal.open(this.modalContent, { size: 'lg' });
  }
  brands:any[];
  selectedBrand:any;
  selectedId:any;
  twitterModel: TwitterModel = new TwitterModel();
  oAuthTokenResponse: any;
  oAuthTokenSecret: any;

  constructor(
    private formBuilder: FormBuilder,
    private registrationService: RegistrationService,
    private fireauth: AngularFireAuth,
    public router: Router,
    public dashboard: DashboardService,
    public socialAuthService: SocialAuthService,
    private loginService: LoginService,
    private translate: TranslateService,
    private brandService:BrandService,
    private datePipe: DatePipe,
    private socialMediaAccountsService: SocialMediaAccountsService,
    )
     {
    if (this.config.settings.layout_type == 'rtl')
      document.getElementsByTagName('html')[0].setAttribute('dir', this.config.settings.layout_type);
    document.documentElement.style.setProperty('--theme-deafult', this.config.color.primary_color);
    document.documentElement.style.setProperty('--theme-secondary', this.config.color.secondary_color);
  }

  ngOnInit() {
   
    this.formInit();
    this.initializeGoogleSignIn();
  }

 
  formInit() {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.pattern(/^([^\s@]+@[^\s@]+\.[^\s@]+)|([1-9]\d{9})$/)]],
      password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(20)]],
    });
  }

  changelang(lang: any) {
    localStorage.setItem('lang', lang)
    this.translate.use(lang)
  }

  showPassword() {
    this.show = !this.show
  }

    initializeGoogleSignIn() {
    google.accounts.id.initialize({
      client_id: '695072061274-r0biomtp4jss40u1a8uiju64bqgjf0bi.apps.googleusercontent.com',
      callback: this.handleCredentialResponse
    });
    google.accounts.id.renderButton(
      document.getElementById('buttonDiv'),
      { theme: 'outline', size: 'large' }
    );
    google.accounts.id.prompt();
  }

  handleCredentialResponse(response: any) {
    console.log("Encoded JWT ID token: " + response.credential);
    const token = response.credential;
    const decoded = jwtDecode(token);
    console.log(decoded);

  }

  loginWithFacebook(): void {
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then(async (data) => {
      console.log("data : ", JSON.stringify(data));
      this.socialUser = data;
      const first_name = data.firstName
      const email = data.email
      const photoUrl = data.photoUrl
      const last_name = data.lastName

      this.userData.firstName = first_name;
      this.userData.lastName = last_name
      this.userData.email = email
      this.userData.userName = email
      this.userData.login_Keyword = 'Facebook'

      this.registrationService.registration(this.userData).subscribe(async (data: any) => {
        console.log("data StatusCode: ", data.StatusCode);
        const StatusCode = data['StatusCode']
        if (data['StatusCode'] === 200) {

          console.log('Res login------>', data['Response']);
          console.log('Res login Uid------>', data['Response'].uid);
          await localStorage.setItem('userId', data['Response'].uid)
          await localStorage.setItem('email', data['Response'].email)
          await localStorage.setItem("token", data['Token']);
          await localStorage.setItem('firstName', data['Response'].firstName)
          await localStorage.setItem('lastName', data['Response'].lastName)
          await localStorage.setItem('profieImage', data['Response'].profieImage)
          localStorage.setItem('orgId', data['Response'].org_Uid)
          await localStorage.setItem('profieImage', data['Response'].profieImage)

          this.router.navigateByUrl('/auth/media-post')

        }
        else if (data['StatusCode'] === 201) {
          console.log('Res login------>', data['Response']);
          console.log('Res login Uid------>', data['Response'].uid);
          await localStorage.setItem('userId', data['Response'].uid)
          await localStorage.setItem('email', data['Response'].email)
          await localStorage.setItem("token", data['Token']);
          await localStorage.setItem('firstName', data['Response'].firstName)
          await localStorage.setItem('lastName', data['Response'].lastName)
          await localStorage.setItem('profieImage', data['Response'].profieImage)
          localStorage.setItem('orgId', data['Response'].org_Uid)
          await localStorage.setItem('profieImage', data['Response'].profieImage);
          this.getAllBrand();
          setTimeout(() => {
            this.getFeedData();
            }, 1000);
         
        }

        else {
          Swal.fire({
            icon: 'error',
            text: 'Something went wrong',
            confirmButtonText: 'Ok',
          });

        }

      }, async (error) => {
        // Handle errors
        console.error('Error:', error);
        if (error.status === 409) {
          this.router.navigateByUrl('/planer-calender')

        }
      }

      )

    }).catch(err => {
      Swal.fire({
        icon: 'error',
        text: 'Something went wrong',
        confirmButtonText: 'Ok',
      });
    })

  };
  googleSignIn() {
    return this.fireauth.signInWithPopup(new GoogleAuthProvider).then(res => {
      console.log("res : ", JSON.stringify(res.user.email));
      const google_email = res.user.email
      const name = res.user.displayName
      const mobile_number = res.user.providerData[0].phoneNumber
      const photo_url = res.user.providerData[0].phoneNumber
      const nameParts = name.split(' '); // Splitting the full name by space
      const firstName = nameParts[0]; // This will be "Raj"
      if (!firstName) {
        // If firstName is null or empty, assign the full name to userData.firstName
        this.userData.firstName = name;
      } else {
        // If firstName exists, assign it to userData.firstName
        this.userData.firstName = firstName;
      }
      this.userData.lastName = ''
      this.userData.userMobileNumber = mobile_number
      this.userData.email = google_email
      this.userData.country = ''
      this.userData.userName = google_email
      this.userData.login_Keyword = 'Google'


      console.log("userData : ", this.userData);

      this.registrationService.registration(this.userData).subscribe(async data => {
        console.log("data : ", data['StatusCode']);
        const StatusCode = data['StatusCode']
        if (data['StatusCode'] === 200) {

          console.log('Res login------>', data['Response']);
          console.log('Res login Uid------>', data['Response'].uid);
          await localStorage.setItem('userId', data['Response'].uid)
          await localStorage.setItem('email', data['Response'].email)
          await localStorage.setItem("token", data['Token']);
          await localStorage.setItem('firstName', data['Response'].firstName)
          await localStorage.setItem('lastName', data['Response'].lastName)
          await localStorage.setItem('profieImage', data['Response'].profieImage)
          localStorage.setItem('orgId', data['Response'].org_Uid)
          await localStorage.setItem('profieImage', data['Response'].profieImage)

          this.router.navigateByUrl('/auth/media-post')

        }
        else if (data['StatusCode'] === 201) {
          console.log('Res login------>', data['Response']);
          console.log('Res login Uid------>', data['Response'].uid);
          await localStorage.setItem('userId', data['Response'].uid)
          await localStorage.setItem('email', data['Response'].email)
          await localStorage.setItem("token", data['Token']);
          await localStorage.setItem('firstName', data['Response'].firstName)
          await localStorage.setItem('lastName', data['Response'].lastName)
          await localStorage.setItem('profieImage', data['Response'].profieImage)
          localStorage.setItem('orgId', data['Response'].org_Uid)
          await localStorage.setItem('profieImage', data['Response'].profieImage);
          this.getAllBrand();
          
          //setTimeout(() => {
            this.getFeedData();
        //  }, 1000);
          setTimeout(() => {
            this.router.navigateByUrl('/planer-calender')
          }, 2000);

        }
        else {
          Swal.fire({
            icon: 'error',
            text: 'Something went wrong',
            confirmButtonText: 'Ok',
          });

        }

      }, async (error) => {
        // Handle errors
        console.error('Error:', error);
      }

      )


    })
  }

  async login() {
    localStorage.setItem('tourShown', 'true');

    this.registrationModel.userName = await this.loginForm.controls["email"].value;
    console.log('this.mobNo', this.email);
    localStorage.setItem('email', this.email);
    this.registrationModel.password = await this.loginForm.controls["password"].value;

    this.loginService.login(this.registrationModel).subscribe(async (data: any) => {
      if (data['StatusCode'] === 200) {

        console.log('Res login------>', data['Response']);
        console.log('Res login Uid------>', data['Response'][0]['uid']);
        await localStorage.setItem('userId', data['Response'][0]['uid'])
        await localStorage.setItem('email', data['Response'][0]['email'])
        await localStorage.setItem("token", data['Token']);
        await localStorage.setItem('firstName', data['Response'][0]['firstName'])
        await localStorage.setItem('lastName', data['Response'][0]['lastName'])
        await localStorage.setItem('profieImage', data['Response'][0]['profieImage'])
        localStorage.setItem('orgId', data['Response'][0]['org_Uid'])

        // this.requestPermission1();
        // this.fcmToken()
        this.getAllBrand();
        setTimeout(() => {
          this.getFeedData();
          console.log('inside time sout');
          
        }, 1000);
        localStorage.setItem('orgId', data['Response'][0]['org_Uid']);
       
      
      }
    },
      async (error) => {
        if (error.status === 409) {
          Swal.fire({
            icon: 'error',
            title: 'Alert',
            text: 'Something Went Wrong.',
            confirmButtonText: 'Ok',
          });
        }
        else if (error.status === 401) {
          Swal.fire({
            icon: 'error',
            title: 'Alert',
            text: 'Password does not match',
            confirmButtonText: 'Ok',
          });
        }
        else if (error.status === 404) {
          Swal.fire({
            icon: 'error',
            title: 'Alert',
            text: 'You are not registered ! Please create Account',
            confirmButtonText: 'Ok',
          });
        }
        // else {
        //   Swal.fire({
        //     icon: 'error',
        //     title: 'Alert',
        //     text: 'Internal Server Error',
        //     confirmButtonText: 'Ok',
        //   });
        // }
        else if (error.status === 500) {

          Swal.fire({
            icon: 'error',
            title: 'Alert',
            text: 'Internal Server Error',
            confirmButtonText: 'Ok',
          });
        }
      });

  }
  async getFeedData() {
    localStorage.removeItem('calendarData');
    
    console.log(localStorage.getItem('calendarData'));
    
    //GetAll Feed Data
    this.calendarData=[];
    const userId = await localStorage.getItem('userId');
    const brandId = await localStorage.getItem('brandId');
    console.log("brandId----- in login ts 546>>>", brandId);
    (await this.dashboard.getDataNew(userId,brandId)).subscribe(async (data: any) => {
      console.log(data);
      if (data['StatusCode'] == 200) {
        this.post = data['Response']
        this.activeDayIsOpen = true;
        console.log(' this.post inside method 240------->', this.post);
        this.filteredPosts = this.post

        console.log('this.post.length;---->', this.post.length)
        for (let i = 0; i < this.post.length; i++) {
        console.log('new Date()--->',new Date());
        console.log("this.post[i].sheduled_Publish_Date-->>",this.post[i].sheduled_Publish_Date);
        console.log("this.post[i].sheduled_Publish_Time--->>",this.post[i].sheduled_Publish_Time);
        
          const inputDate1 = this.post[i].sheduled_Publish_Date +" "+this.post[i].sheduled_Publish_Time;
      
          console.log("this.formattedDate--->>",this.formattedDate);

          console.log('inputDate1 226---->', inputDate1)
         
          this.apiResponseDate = new Date(this.formattedDate);

          const feedId=this.post[i].feed_id;
          const pageId=this.post[i].page_id;
          this.title = this.post[i].post_title;
          console.log('feedId---->',feedId,'pageId-------->',pageId);
         // this.socialMediaKeyword=this.post[i].social_Media_KeyWord;
          console.log('this.socialMediaKeyword----->',this.post[i].social_Media_Keyword	);
          if(this.post[i].social_Media_Keyword==='facebook'){
            this.socialMediaColor=colors.facebook;
            this.socialMediaKeyword='facebook'
          }else  if(this.post[i].social_Media_Keyword==='Instagram'){
            this.socialMediaColor=colors.insta;
            this.socialMediaKeyword='Instagram'
          }
          else  if(this.post[i].social_Media_Keyword==='Twitter'){
            this.socialMediaColor=colors.tweeter;
            this.socialMediaKeyword='Twitter'
          }
          else  if(this.post[i].social_Media_Keyword==='Pinterest'){
            this.socialMediaColor=colors.pinterest;
            this.socialMediaKeyword='Pinterest'
          }else if (this.post[i].social_Media_Keyword === 'facebookGroup' || this.post[i].social_Media_Keyword==='Facebook') {
            this.socialMediaColor = colors.fbGroup;
            this.socialMediaKeyword='facebookGroup'
          } 
          else if (this.post[i].social_Media_Keyword === 'LinkedIn Business Profile') {
            this.socialMediaColor = colors.linkedInd;
            this.socialMediaKeyword='LinkedIn Business Profile'
          }
          else if(this.post[i].social_Media_Keyword === 'LinkedIn Page'){
            this.socialMediaColor = colors.linkedInd;
            this.socialMediaKeyword='LinkedIn Page'
          }
          // else if (this.post[i].social_Media_Keyword === 'LinkedIn Page') {
          //   this.socialMediaColor = colors.fbGroup;
          // }
          const data = {
            start: inputDate1,
           // end: this.apiResponseDate,
            title:this.title,
            color: this.socialMediaColor,
            actions: this.actions,
            socialMediaKeyword:this.socialMediaKeyword,
            allDay: true,
            feedId:feedId,
            pageId:pageId,
            resizable: {
              beforeStart: true,
              afterEnd: true
            },
            draggable: true
          }
          //this.events.push(data);
          this.calendarData.push(data)
        }
        console.log('this.events---->',this.calendarData);
        console.log('this.calendarData---->',JSON.stringify(this.calendarData));
        await localStorage.setItem('calendarData', JSON.stringify(this.calendarData))
        console.log('local storage data of calendar---->', await localStorage.getItem('calendarData'));
        setTimeout(() => {
          //this.router.navigateByUrl('/planer-calender')
          this.router.navigateByUrl('/auth/login-otp')
        }, 1000);
        
      }
      else if (data['StatusCode'] == 404) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "No Data Found!",
        });
        setTimeout(() => {
         // this.router.navigateByUrl('/planer-calender')
         this.router.navigateByUrl('/auth/login-otp')
        }, 1000);
      }
      else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
        //setTimeout(() => {
         // this.router.navigateByUrl('/planer-calender')
         this.router.navigateByUrl('/auth/login-otp')
      //  }, 1000);
      }
      setTimeout(() => {
        //this.router.navigateByUrl('/planer-calender')
        this.router.navigateByUrl('/auth/login-otp')
      }, 1000);
    },
      async (error) => {

        if (error.status === 409) {
          Swal.fire({
            icon: 'error',
            title: 'Alert',
            text: 'Something Went Wrong.',
            confirmButtonText: 'Ok',
          });
          setTimeout(() => {
            this.router.navigateByUrl('/planer-calender')
          }, 1000);
        }
       
       
        else if (error.status === 404) {

          // Swal.fire({
          //   icon: 'error',
          //   title: 'Alert',
          //   text: 'Data Not Found',
          //   confirmButtonText: 'Ok',
          // });
          setTimeout(() => {
            this.router.navigateByUrl('/planer-calender')
          }, 1000);
        }
        else   if (error.status === 500) {

          Swal.fire({
            icon: 'error',
            title: 'Alert',
            text: 'Internal Server Error...',
            confirmButtonText: 'Ok',
          });
        }
        setTimeout(() => {
          this.router.navigateByUrl('/planer-calender')
        }, 1000);
      })
    }
    async forgotPass(){
      if  (this.loginForm.controls.email.invalid) {
        console.log('Forgot password logic goes here1');
  
        Swal.fire({
          icon: 'error',
          title: 'Alert',
          text: 'Please insert your Email address',
        });
      } else {
        console.log('Forgot password logic goes here');
      localStorage.setItem('forgotEmail',await this.loginForm.controls["email"].value)
  
      this.router.navigateByUrl('/auth/forgot-password')
      }

  }

  async getAllBrand() {
     this.brands=[]

    const userId = localStorage.getItem('userId');
    console.log('this.userId', userId);

    (await this.brandService.getBrand(userId)).subscribe(async (data: any) => {

      if (data['StatusCode'] === 200) {
        console.log('data', data);

        this.brands = data['Response'];
        console.log('this.brands', this.brands);
        this.selectedBrand = this.brands[0].brandName;
        console.log('Selected Brand:', this.selectedBrand);
        localStorage.setItem('brandName', this.selectedBrand)

        this.selectedId = this.brands[0].uid;
        
        localStorage.setItem('brandId', this.brands[0].uid)
        console.log('Selected brandId in login ts :',await localStorage.getItem('brandId'));

        //this.getFeedData();
        // Set the default selected brand
        if (this.brands.length > 0) {
          this.selectedBrand = this.brands[0].brandName;
          this.selectedId = this.brands[0].uid
        }

        console.log('this.brands', this.brands);
      }
    });
  }


   // for Twitter Accounts
   async loginWithTwitter() {

    await this.socialMediaAccountsService.twitterRequestToken().subscribe((data: any) => {
      console.log("data : ", data.TokenData);
      console.log("oauth_token : ", data.TokenData.oauth_token);
      console.log("oauth_token_secret : ", data.TokenData.oauth_token_secret);
      console.log("oauth_callback_confirmed : ", data.TokenData.oauth_callback_confirmed);
      const oauth_token = data.TokenData.oauth_token;
      const twitterAuthorizedURL = `https://api.twitter.com/oauth/authorize?oauth_token=${oauth_token}`;
      this.openTwitterPopup(twitterAuthorizedURL)
    })
  }


//linkdin login flow
  //connect to linkedIn
  private linkedinCredentials = {
    // clientId: "7783fsjw6a70hy",
    // clientSecret: 'RD9Wq1huuKkcBFpE',
    // clientId: "7729u395o943bc",
    // clientSecret: 'I0VVMVFUQsKGnQSB',
       clientId: "7783fsjw6a70hy",
       clientSecret: 'cdEYpEMfliP18oGc',
    // redirectUri: "http://localhost:4200/simple/first-page",
    redirectUri: `${redirectUrl}`,
    // redirectUri:`${localRedirectUrl}`,
   // scope: "openid%20profile%20r_ads_reporting%20r_organization_social%20rw_organization_admin%20w_member_social%20r_learningdata%20r_ads%20w_organization_social%20rw_ads%20r_basicprofile%20r_organization_admin%20email%20r_1st_connections_size",
    scope: "openid%20profile%20r_ads_reporting%20r_organization_social%20rw_organization_admin%20w_member_social%20r_ads%20w_organization_social%20rw_ads%20r_basicprofile%20r_organization_admin%20email%20r_1st_connections_size",
  };




  public async openTwitterPopup(url: string): Promise<void> {
    const width = 600;
    const height = 400;
    const left = (window.innerWidth - width) / 2;
    const top = (window.innerHeight - height) / 2;

    const options = `width=${width},height=${height},top=${top},left=${left},resizable=yes,scrollbars=yes`;

    try {

      const authWindow = window.open(url, 'Twitter Auth', options);
      console.log("authWindow ---- 127", authWindow);
      localStorage.setItem('authWindowName', authWindow.name);
      localStorage.setItem('authWindowURL', authWindow.location.href);

      const checkPopup = setInterval(async () => {
        if (authWindow.window.location.href.includes('http://localhost:4200')) {
          const callbackURL = authWindow.location.href;
          const url = new URL(callbackURL);
          const oauthVerifier = url.searchParams.get('oauth_verifier');
          const oauthToken = url.searchParams.get('oauth_token');

          console.log("oauthVerifier: ", oauthVerifier);
          console.log("oauthToken: ", oauthToken);

          if (oauthVerifier && oauthToken) {

            this.twitterModel.oauthToken = oauthToken
            this.twitterModel.oauthVerifier = oauthVerifier

            await this.socialMediaAccountsService.twitterRequestAccessToken(this.twitterModel).subscribe(async (data: any) => {
              console.log("after insert data : ", data);

              // const twitter_accounts_data = {
              //   oAuthTokenResponse: data.Response.oAuthTokenResponse,
              this.userData.firstName = data.Response.twitterUserName
              this.userData.lastName = ''
              this.userData.userId = data.Response.twitterUserId
              this.userData.country = ''
              this.userData.userName = data.Response.twitterUserName
              this.userData.login_Keyword = 'Twitter'
      
             
              this.registrationService.registration(this.userData).subscribe(async (data: any) => {
                console.log("data StatusCode: ", data.StatusCode);
                const StatusCode = data['StatusCode']
                if (data['StatusCode'] === 200) {
        
                   console.log('Res login 200------>', data['Response']);
                  console.log('Res login Uid------>', data['Response'].uid);
                  await localStorage.setItem('userId', data['Response'].uid)
                  await localStorage.setItem('email', data['Response'].email)
                  await localStorage.setItem("token", data['Token']);
                  await localStorage.setItem('firstName', data['Response'].firstName)
                  await localStorage.setItem('lastName', data['Response'].lastName)
                  await localStorage.setItem('profieImage', data['Response'].profieImage)
                  localStorage.setItem('orgId', data['Response'].org_Uid)
                  await localStorage.setItem('profieImage', data['Response'].profieImage)
        
                  this.router.navigateByUrl('/auth/media-post')
        
                }
                else if (data['StatusCode'] === 201) {
                  console.log('Res login 201------>', data['Response']);
                  console.log('Res login Uid------>', data['Response'].uid);
                  await localStorage.setItem('userId', data['Response'].uid)
                  await localStorage.setItem('email', data['Response'].email)
                  await localStorage.setItem("token", data['Token']);
                  await localStorage.setItem('firstName', data['Response'].firstName)
                  await localStorage.setItem('lastName', data['Response'].lastName)
                  await localStorage.setItem('profieImage', data['Response'].profieImage)
                  localStorage.setItem('orgId', data['Response'].org_Uid)
                  await localStorage.setItem('profieImage', data['Response'].profieImage);
                  this.getAllBrand();
                  this.getFeedData();
                  setTimeout(() => {
                    this.router.navigateByUrl('/planer-calender')
                  }, 1000);
        
                }
        
                else {
                  Swal.fire({
                    icon: 'error',
                    text: 'Something went wrong',
                    confirmButtonText: 'Ok',
                  });
        
                }
        
              }, async (error) => {
                // Handle errors
                console.error('Error:', error);
                if (error.status === 409) {
                  this.router.navigateByUrl('/planer-calender')
        
                }
              }
        
              )

            })

            authWindow.close();
            clearInterval(checkPopup);
          }
        }

        if (!authWindow || authWindow.closed) {
          clearInterval(checkPopup);

        }
      }, 1000);

      if (!authWindow) {
        throw new Error('Popup blocked. Please enable popups for this site.');
      }

    } catch (error) {
      console.error("error --- ", error);
    }
  }

  loginWithLinkdin() {
    localStorage.setItem("clientId", this.linkedinCredentials.clientId);
    localStorage.setItem("clientSecret", this.linkedinCredentials.clientSecret);

    const state = this.generateRandomState();  // or use this.generateSecureRandomState()

    console.log("generateSecureRandomState 1887 is",state)
   // const authUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${this.linkedinCredentials.clientId}&redirect_uri=${this.linkedinCredentials.redirectUri}&scope=${this.linkedinCredentials.scope}&state=foobarDCEeFWf45A53sdfKef424`;
    const authUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${this.linkedinCredentials.clientId}&redirect_uri=${this.linkedinCredentials.redirectUri}&scope=${this.linkedinCredentials.scope}&state=${state}`;
    console.log("authUr------>>>", authUrl);
    console.log('--------------linkedIn 2 connectwithLinkdinAcc-------------------------');
    this.openLinkedInPopup(authUrl);

  }

  generateRandomState(): string {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  }

 // connect with linkedAcc popup
 public async openLinkedInPopup(url: string): Promise<void> {
  const width = 600;
  const height = 400;
  const left = (window.innerWidth - width) / 2;
  const top = (window.innerHeight - height) / 2;

  const options = `width=${width},height=${height},top=${top},left=${left},resizable=yes,scrollbars=yes`;

  try {

    console.log("this.loginService.isAuthenticated ----> 1432", this.loginService.isAuthenticated);


    const authWindow = window.open(url, 'LinkedIn Auth', options);
    this.loginService.isAuthenticated = true;
      console.log("Login With Linkdin 851")

    // const checkPopup = setInterval(async () => {
    //   if (authWindow.window.location.href.includes(this.linkedinCredentials.redirectUri)) {

    //     this.loginService.isAuthenticated = true;
    //     const callbackURL = authWindow.location.href;
    //     console.log("callbackURL : --- 1441 ", callbackURL);

    //     const url = new URL(callbackURL);
    //     const oauthcode = url.searchParams.get('code');
    //     //const oauthState = url.searchParams.get('state');

    //     console.log("oauthcode: ", oauthcode);
    //     //console.log("oauthState: ", oauthState);

    //     if (oauthcode) {
    //       console.log('Access Token---------------->>>');

    //       this.linkedInData.clientId = this.linkedinCredentials.clientId;
    //       this.linkedInData.clientSecret = this.linkedinCredentials.clientSecret;
    //       this.linkedInData.code = oauthcode;
    //       this.linkedInData.redirect_uri = this.linkedinCredentials.redirectUri

    //       console.log('this.linkedInData--->', this.linkedInData);

    //       this.linkedInService.getToken(this.linkedInData).subscribe(async (data) => {
    //         console.log('data---->', data);
    //         if (data['StatusCode'] === 200) {
    //           this.accessToken = data['Response']['accessToken'];
    //           localStorage.setItem('linkedInToken', this.accessToken);
    //           let given_name = data['Response']['given_name'];
    //           let family_name = data['Response']['family_name'];
    //           this.sub = data['Response']['sub'];
    //           let picture = data['Response']['picture'];
    //           console.log('accessToken linkedIn--->', this.accessToken, 'given_name--->', given_name
    //             , 'family_name---->', family_name);

    //           //get All LinkedIn User Account
    //           if (this.socialMediaKeyword === 'LinkedInAccount') {
    //             console.log('----get All LinkedIn User Account----');
    //             const linkedIn_accounts = {
    //               accessToken: this.accessToken,
    //               brandId: await localStorage.getItem('brandId'),
    //               createdBy: await localStorage.getItem('userId'),
    //               id: this.sub.toString(),
    //               linkdinUserId: 'null',
    //               linkdinUserName: 'null',
    //               localizedName: given_name + ' ' + family_name,
    //               loginStatus: "0",
    //               messeage: '',
    //               orgId: await localStorage.getItem('orgId'),
    //               profileImage: 'null',
    //               profileImageLink: picture,
    //               socialMediaKeyword: "LinkedInAccount",
    //               uid: "",
    //               userId: await localStorage.getItem('userId')
    //             }
    //             let responseData: any;
    //             responseData = linkedIn_accounts;

    //             if (this.linkedInAccountArray.length > 0) {
    //               for (let j = 0; j < this.linkedInAccountArray.length; j++) {
    //                 if (this.linkedInAccountArray[j].id !== responseData.id) {
    //                   this.linkedInAccountArray.push(responseData)

    //                   console.log('this.linkedInAccountArray push of linkedin account 1=>', this.linkedInAccountArray);

    //                   this.linkedInAccountArray = this.linkedInAccountArray.filter((page, index, self) =>
    //                     index === self.findIndex((p) => p.id === page.id)
    //                   );
    //                   console.log('this.linkedInAccountArray push of linkedin account 1 2=>', this.linkedInAccountArray);

    //                 }
    //               }
    //             } else {
    //               this.linkedInAccountArray.push(responseData)
    //             }
    //           }

    //           //get LinkedIn User Account Pages
    //           else if (this.socialMediaKeyword === 'LinkedInPage') {
    //             console.log('get All LinkedIn User Account Pages');

    //             (await this.linkedInService.getLinkedInUserPages(given_name, family_name, this.accessToken)).subscribe(async (data: any) => {
    //               console.log('user info of linkedIn data-->', data['Response']);
    //               if (data['StatusCode'] === 200) {


    //                 let responseData: any[];
    //                 responseData = data['Response']

    //                 //  console.log('length--->', data['Response'].length);

    //                 //response fb_group_data
    //                 for (let i = 0; i < responseData.length; i++) {
    //                   const linkedIn_accounts1 = {
    //                     accessToken: this.accessToken,
    //                     brandId: await localStorage.getItem('brandId'),
    //                     createdBy: await localStorage.getItem('userId'),
    //                     id: responseData[i].id.toString(),
    //                     linkdinUserId: 'null',
    //                     linkdinUserName: 'null',
    //                     localizedName: responseData[i].localizedName,
    //                     loginStatus: "0",
    //                     messeage: responseData[i].messeage,
    //                     orgId: await localStorage.getItem('orgId'),
    //                     profileImage: 'null',
    //                     profileImageLink: responseData[i].pageProfileUrl,
    //                     socialMediaKeyword: "LinkedInPage",
    //                     uid: "",
    //                     userId: await localStorage.getItem('userId')

    //                   }
    //                   if (this.linkedIn_accounts_array.length > 0) {
    //                     for (let j = 0; j < this.linkedIn_accounts_array.length; j++) {
    //                       if (this.linkedIn_accounts_array[j].id !== responseData[i].id) {
    //                         this.linkedIn_accounts_array.push(linkedIn_accounts1)
    //                         console.log('this.linkedIn_accounts_array push of linkedin account 1=>', this.linkedIn_accounts_array);

    //                         this.linkedIn_accounts_array = this.linkedIn_accounts_array.filter((page, index, self) =>
    //                           index === self.findIndex((p) => p.id === page.id)
    //                         );
    //                         console.log('this.linkedIn_accounts_array push of linkedin account 1 2=>', this.linkedIn_accounts_array);

    //                       }
    //                     }
    //                   } else {
    //                     this.linkedIn_accounts_array.push(linkedIn_accounts1)
    //                   }

    //                 }

    //               }
    //             })
    //           }
    //         }
    //         else  //new added by kiran 21_11_2024
    //         {
    //          Swal.fire({
    //            title: 'Error',
    //            text: 'Token generation failed. Please try again.',
    //            icon: 'error',
    //            confirmButtonText: 'OK'
    //          });
    //         }
    //       }),
          
    //       authWindow.close();
    //       clearInterval(checkPopup);
    //     }
    //   }
    //   if (!authWindow || authWindow.closed) {
    //     clearInterval(checkPopup);
    //   }
    // }, 1000);

    // if (!authWindow) {
    //   throw new Error('Popup blocked. Please enable popups for this site.');
    // }

  } catch (error) {
    console.error("error --- ", error);
  }
}



}


