import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from "./shared/shared.module";
import { AppRoutingModule } from './app-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, } from '@angular/core';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService, SocialAuthServiceConfig, SocialLoginModule } from 'angularx-social-login';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MentionModule } from 'angular-mentions';
// // for HttpClient import:
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
// // for Router import:
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
// // for Core import:
import { LoadingBarModule } from '@ngx-loading-bar/core';

import { CookieService } from 'ngx-cookie-service';

import { AppComponent } from './app.component';
import { DragDropModule } from 'primeng/dragdrop';
import { DropdownModule } from 'primeng/dropdown';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule, DatePipe } from '@angular/common';  
import { SignUpComponent } from './auth/sign-up/sign-up.component';
import { RegisterComponent } from './auth/register/register.component';
import { LoginComponent } from './auth/login/login.component';
import { DashboardModule } from './components/dashboard/dashboard.module';
import {  OtpComponent} from "./auth/otp/otp.component";
import {  CreateBrandComponent} from "./auth/create-brand/create-brand.component";

import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown'
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { Question2Component } from "./quetions/question2/question2.component";
import { Question3Component } from "./quetions/question3/question3.component";

import {Question1Component} from '../app/quetions/question1/question1.component'
import {Question4Component} from '../app/quetions/question4/question4.component'

import { AnalyticsModule } from 'angular-analytics';
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

import { ForgetPasswordComponent } from './auth/forget-password/forget-password.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from 'src/environments/environment';
import { MediaPostComponent } from './auth/media-post/media-post.component';
import { StartComponent} from './auth/start/start.component';
import { initializeApp } from "firebase/app";
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { NgxCaptchaModule } from 'ngx-captcha';
import { LoginOtpComponent } from './auth/login-otp/login-otp.component';
initializeApp(environment.firebaseConfig);
@NgModule({
  
  declarations: [
    AppComponent,
    SignUpComponent,
    RegisterComponent,
    LoginComponent,
    LoginOtpComponent,
    ForgetPasswordComponent ,
    OtpComponent,
    MediaPostComponent,
    CreateBrandComponent,
    
    Question1Component,
    Question4Component,
    Question2Component,
    Question3Component,
    StartComponent,
  
  ],
  imports: [
  // AnalyticsModule.forRoot({ trackingCode: 'G-1TV3QM1XQW' }),

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
    }),
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    NgxCaptchaModule,
    BrowserAnimationsModule,
    OverlayModule,
    SharedModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule, 
    DropdownModule,
    DragDropModule,
    NgOtpInputModule,
//     // for HttpClient use:
    LoadingBarHttpClientModule,
//     // for Router use:
    LoadingBarRouterModule,
//     // for Core use:
    LoadingBarModule,
    TimepickerModule.forRoot(),
    NgxDropzoneModule,
    MentionModule,
    NgMultiSelectDropDownModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireMessagingModule,
    
    
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [ 
    CookieService,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false, //keeps the user signed in
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider('695072061274-r0biomtp4jss40u1a8uiju64bqgjf0bi.apps.googleusercontent.com') // your client id
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('725756832392921'),
          },

        ]
      } as SocialAuthServiceConfig
    },SocialAuthService,DatePipe,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
