import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Route, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { RegistrationModel } from 'src/app/models/registration-model';
import { VerifyOtp } from 'src/app/models/verify-otp';
import { RegistrationService } from 'src/app/services/registration.service';
import { VerifyOtpService } from 'src/app/services/verify-otp.service';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { ReCaptcha2Component } from 'ngx-captcha';
import { PlatformLocation } from '@angular/common';

@Component({
  selector: 'app-login-otp',

  templateUrl: './login-otp.component.html',
  styleUrl: './login-otp.component.scss'


})

export class LoginOtpComponent implements OnInit {

  @ViewChild('captchaElem') captchaElem: ReCaptcha2Component;

  protected aFormGroup:FormGroup
  siteKey:string ="6LfzG4wpAAAAAHgfG5p_ZXGWtSFmdO33S8_wyyNY";
  public loading = false;
  public show: boolean = false;
  userData: RegistrationModel = new RegistrationModel();
  otpForm!: FormGroup
  otpData: VerifyOtp = new VerifyOtp();
  otp: any;
  userData2: any;
  userData1: any;
  timer: any;
  interval: any;
  time: BehaviorSubject<string> = new BehaviorSubject('00:00');
  resend: boolean = false;
  mobNo: any;
  config = {
    length: 5,
    allowNumbersOnly: true,
    inputClass: 'otp-input-style',

  };
  userMobileNumber: any;
  uid: any;
  userId: any;
  userEmailId: string;
  token: any;
  captchaResolved: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private otpservice: VerifyOtpService,
    private router: Router,
    private registrationservice: RegistrationService,
    private translate:TranslateService,
    private platformLocation: PlatformLocation
  ) {
    this.otpForm = this.formBuilder.group({
      recaptcha: ['', Validators.required], // Ensure reCAPTCHA is required
      // Other form controls for OTP input
    });
    history.pushState(null, '', location.href);  
      this.platformLocation.onPopState(() => { 
             history.pushState(null, '', location.href);  
      })
   }


  async ngOnInit() {
    this.aFormGroup = this.formBuilder.group({
      recaptcha: ['', Validators.required]
    });

    this.formInit();
    this.startTimer(1);
    this.userEmailId = localStorage.getItem('email')//await localStorage.getItem('userMobieNumber')

    this.uid = await localStorage.getItem('userId')
    console.log('this.uid', this.uid)
  }

  // onCaptchaResolved(captchaResponse: string) {
  //   if (captchaResponse) {
  //     // ReCAPTCHA completed successfully
  //     this.captchaResolved = true; // Set a flag to indicate reCAPTCHA is resolved
  //   } else {
  //     this.captchaResolved = false; // Reset the flag if reCAPTCHA fails or is not completed
  //   }
  // }
  formInit() {
    this.otpForm = this.formBuilder.group({
      otp1: [""],
      otp2: [""],
      otp3: [""],
      otp4: [""],
      otp5: [""],

    })

  }
  onOtpChange(event: any) {
    this.otp = event;
    console.log(this.otp);
  }
  gotoNextField(event: any, next: any, prev: any): any {
    if (event.target.value.length < 1 && prev) {
      prev.setFocus()
    }
    else if (next && event.target.value.length > 0) {
      next.setFocus();
    }
    else {
      return 0;
    }
  }

  onCaptchaResolved(captchaResponse: string): void {
    console.log('Captcha resolved:', captchaResponse);
  }
  async otpVerify() {
      if (this.aFormGroup.get('recaptcha').value) {
        // reCAPTCHA is valid, proceed with verification
        console.log('reCAPTCHA verified, proceeding with OTP verification...');
     

    this.otpData.otp = this.otp;
    console.log("otp --->", this.otpData.otp);
    this.otpData.uId = await localStorage.getItem('userId')
    console.log('this.otpData.uId', this.otpData.uId);

    (await this.otpservice.otpVerify(this.otpData)).subscribe((data: any) => {
      console.log('data', data)
      if (data["StatusCode"] === 200) {
        console.log('data', data)
      //this.register();
       // this.router.navigateByUrl('/auth/media-post')
       this.router.navigateByUrl('/planer-calender')
        console.log('-----1----')
      }

    },
      async (error) => {
        // Handle errors
        console.error('Error:', error);

        // You can perform additional actions based on the error status code or message
        if (error.status === 409) {

          Swal.fire({
            icon: 'error',
            title: 'Alert',
            text: 'OTP Does Not Match',
            confirmButtonText: 'Ok',
          });
        }
      })

    } else {
      // reCAPTCHA is invalid, show error message
      alert('Please complete the reCAPTCHA verification.');
    }
  }
  async resendOtp() {
    this.startTimer(1);

    this.otpData.userMobileNumber = await localStorage.getItem('userMobieNumber')

    this.otpData.email = await localStorage.getItem('email')

    console.log(' this.userMobileNumber', this.otpData.userMobileNumber)

    this.otpData.uId = await localStorage.getItem('userId')
    console.log(' this.userId', this.otpData.uId);
    (await this.otpservice.otpResend(this.otpData)).subscribe((data: any) => {
      console.log('data', data)

      if (data['StatusCode'] === 200) {
        Swal.fire({
          icon: 'success',
          // title: 'Custom Title',
          text: 'Otp Send SuccessFully.',
          confirmButtonText: 'Ok',
        });
      }

      else if (data['StatusCode'] === 409) {
        console.log('---------2--------')

        console.log('-----------409----------')
        Swal.fire({
          icon: 'error',
          title: 'Alert',
          text: 'Otp Not Send SuccessFully.',
          confirmButtonText: 'Ok',
        });
      }

    })
  }
  startTimer(duration: number) {
    this.timer = duration * 30;
    setInterval(() => {
      this.updateTimeValue();
    }, 1000
    );
    this.resend = true;
  }

  stopTimer() {
    clearInterval(this.interval)
    this.time.next('00:00');

    this.resend = false;
  }

  updateTimeValue() {
    let minutes: any = this.timer / 60;
    let seconds: any = this.timer % 60;

    minutes = String('0' + Math.floor(minutes)).slice(-2);
    seconds = String('0' + Math.floor(seconds)).slice(-2);

    const text = minutes + ':' + seconds;
    this.time.next(text);

    --this.timer;

    if (this.timer < 0) {
      this.stopTimer();
    }
  }


  showPassword() {
    this.show = !this.show;
  }
  
}
